// Entry point for the build script in your package.json

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"

import "chartkick/chart.js"

// import "./stylesheets/application"
// import "./images"

import "./jquery"

import "@popperjs/core"

import * as bootstrap from "bootstrap"

Rails.start()
ActiveStorage.start()

// window.stripe = require('stripe')(process.env.STRIPE_PUBLISHABLE_KEY);

window.toastr = require('toastr');
window.toastr.options = {
  tapToDismiss: true,
  timeOut: 5000,
  extendedTimeOut: 0,
  closeButton: false,
}

document.addEventListener('DOMContentLoaded', () => {
  var toastElList = [].slice.call(document.querySelectorAll('.toast'))
  var toastList = toastElList.map(function(toastEl) {
    return new bootstrap.Toast(toastEl).show() // No need for options; use the default options
  })
})

// Handles fade in/out on scroll
$(window).on("load", function () {
  $('.fade_on_scroll').each(function () {
    $(this).css({ opacity: 0, transform: 'translateY(35px)', transition: 'all 0.69s' });
  });

  $(window).on('scroll', function fade() {
    var animation_height = 350;

    $('.fade_on_scroll').each(function () {
      var objectTop = $(this).offset().top;
      var windowBottom = $(window).scrollTop() + $(window).innerHeight();

      if (objectTop < windowBottom - animation_height) {
        $(this).css({ opacity: 1, transform: 'translateY(0px)' });
      } else {
        $(this).css({ opacity: 0, transform: 'translateY(35px)'});
      }
    });
  });

  $(window).scroll()
});

$(document).ready(function () {
  $('#animated-bars').on('click', function () {
    $('body').toggleClass('sidebar-toggled');
  });
});

$(document).ready(function () {
  $('.sensitive-data-toggle').on('click', function () {
    const el = $(this);
    const data = el.data();
    const outputEl = el.next();

    const star = '*'

    outputEl.html(data.visible ? star.repeat(12) : data.value)
    el.toggleClass('bi-eye');
    el.toggleClass('bi-eye-slash');
    el.toggleClass('text-primary');

    el.attr('data-bs-original-title', data.visible ? 'Vis' : 'Skjul');
    let btn_tooltip = bootstrap.Tooltip.getInstance(el);
    btn_tooltip.show();

    el.data('visible', !data.visible);
  });
});

$(document).ready(function () {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
});

$(document).ready(function () {
  $('.copyable').on('click', function () {
    var el = $(this);
    var value = el.data('value');

    navigator.clipboard.writeText(value).then(() => {
      el.attr('data-bs-original-title', 'Kopieret!');
      let btn_tooltip = bootstrap.Tooltip.getInstance(el);
      btn_tooltip.show();

      el.attr('data-bs-original-title', 'Kopier');
    });
  });
});

window.CustomToastr = class CustomToastr {
  static success(message) {
    toastr.success(`<div class='d-flex align-items-center'><i class="bi bi-check-circle-fill me-2 text-success"></i><span>${message}</span></div>`);
  }

  static error(message) {
    toastr.error(`<div class='d-flex align-items-center'><i class="bi bi-exclamation-circle-fill me-2 text-danger"></i><span>${message}</span></div>`);
  }

  static toast(type, message) {
    switch (type) {
      case "notice":
      case "success":
        return CustomToastr.success(message);
      default:
        return CustomToastr.error(message);
    }
  }
}

// $(function () {
//   setTimeout(function () {
//     $(window).on('activate.bs.scrollspy', function (e) {
//       let target = $("a[href^='#'].nav-link.active").last().attr("href").replace(/^#/, '');
//       document.location.hash = target;
//     });
//   }, 1500);

//   // add event listener for click event on navigation links
//   $('a.nav-link').on('click', function (event) {
//     // prevent default behavior of link click
//     event.preventDefault();

//     // get target section ID from link href
//     var target = $(this).attr("href");

//     // scroll to target section
//     window.scrollTo({
//       top: $(target).offset().top,
//       behavior: 'instant'
//     })
//   });
// });

window.googleMapsCustomStyles = [
  {
    "featureType": "administrative.locality",
    "elementType": "all",
    "stylers": [
      {
        "hue": "#ff0200"
      },
      {
        "saturation": 7
      },
      {
        "lightness": 19
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "administrative.locality",
    "elementType": "labels.text",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "saturation": "-3"
      }
    ]
  },
  {
    "featureType": "administrative.locality",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#748ca3"
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "all",
    "stylers": [
      {
        "hue": "#ff0200"
      },
      {
        "saturation": -100
      },
      {
        "lightness": 100
      },
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "all",
    "stylers": [
      {
        "hue": "#ff0200"
      },
      {
        "saturation": "23"
      },
      {
        "lightness": "20"
      },
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.school",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#ffdbda"
      },
      {
        "saturation": "0"
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "hue": "#ff0200"
      },
      {
        "saturation": "100"
      },
      {
        "lightness": 31
      },
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#f39247"
      },
      {
        "saturation": "0"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels",
    "stylers": [
      {
        "hue": "#008eff"
      },
      {
        "saturation": -93
      },
      {
        "lightness": 31
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "color": "#ffe5e5"
      },
      {
        "saturation": "0"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels",
    "stylers": [
      {
        "hue": "#bbc0c4"
      },
      {
        "saturation": -93
      },
      {
        "lightness": -2
      },
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "geometry",
    "stylers": [
      {
        "hue": "#ff0200"
      },
      {
        "saturation": -90
      },
      {
        "lightness": -8
      },
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "all",
    "stylers": [
      {
        "hue": "#e9ebed"
      },
      {
        "saturation": 10
      },
      {
        "lightness": 69
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "all",
    "stylers": [
      {
        "hue": "#e9ebed"
      },
      {
        "saturation": -78
      },
      {
        "lightness": 67
      },
      {
        "visibility": "simplified"
      }
    ]
  }
]
